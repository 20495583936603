import React from 'react';

const Services = () => {

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="container pb-5 pt-md-5 mt-md-5">
      <div className="choose-bundle-wrapper py-5 my-5 text-center mx-auto">
        <h2 className='fw-normal display-5'>Want to <span className='text-teal'>make extra money</span> by using the latest AI? Or Want to <span className='text-teal'>grow your skills</span> and carrier?</h2>
        <p className='h4 fw-normal lh-base mt-3'>If the answer is <span className='text-teal'>YES</span>, then jump in right away!</p>
        <div className='d-flex justify-content-center mt-4'>
          <button class="button-teal" role="button" onClick={()=>openInNewTab('https://cosmofeed.com/vp/65fa816b51b7df001291280b')}><span class="text text-dark">Buy Now For 199/-</span></button>
        </div>
      </div>
    </div>
  )
}

export default Services