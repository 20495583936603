import React from 'react'
import { useNavigate } from 'react-router-dom';

const Navbar = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="d-flex py-3">
          <div className="logo cursor-pointer" onClick={() => navigate('/')}>
            <h1 className='h3'>cashwith<span className='text-teal'>chatgpt</span></h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar