import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

export default function RefundPolicy() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Navbar />
      <div className='container mb-5 pb-5'>
        <h2 className='fw-normal display-5 mb-5'>Refund Policy </h2>

        <p className='fw-bold mb-1'>Digital Products:</p>
        <p className='text-light-dark'>Due to the nature of digital products, refunds cannot be issued once the files have been downloaded. However, we may consider granting a refund under certain circumstances, such as duplicate orders, and will do so at our reasonable discretion.</p>

        <p className='fw-bold mb-1'>Our Commitment to Your Satisfaction:</p>
        <p className='text-light-dark'>While we do not have an obligation to issue refunds, we are committed to ensuring your satisfaction with your purchase. Our products are our creations, and we stand behind them. If you are not satisfied with the outcome for any reason, please reach out to us, and we will make a reasonable effort to address your concerns and resolve the issue.</p>

        <p className='fw-bold mb-1'>Contact Us:</p>
        <p className='text-light-dark'>We are dedicated to providing excellent customer service. If you have any questions or concerns, please don't hesitate to reach out to us at <span>
          <a className='mail-link' href="mailto:cashwithchatgpt@gmail.com" target='_blank'>
            <span className='text-teal'> cashwithchatgpt@gmail.com.</span>
          </a>
        </span> We are incredibly responsive and will do our best to assist you promptly.</p>

        <p className='fw-bold mb-1'>Refund Process:</p>
        <p className='fw-bold mb-1'>To request a refund, please send an email to <span>
          <a className='mail-link' href="mailto:cashwithchatgpt@gmail.com" target='_blank'>
            <span className='text-teal'> cashwithchatgpt@gmail.com</span>
          </a>
        </span> with the following details:</p>
        <p className='text-light-dark mb-1'>Your name</p>
        <p className='text-light-dark mb-1'>Your age</p>
        <p className='text-light-dark mb-1'>Mobile number</p>
        <p className='text-light-dark mb-1'>Transaction number</p>
        <p className='text-light-dark mb-1'>Payment method</p>
        <p className='text-light-dark mb-1'>Reason for refund (minimum 100 words)</p>
        <p className='text-light-dark mb-1'>Total amount</p>
        <p className='text-light-dark mb-1'>Date & time of purchase</p>
        <p className='text-light-dark mb-1'>Please attach a screenshot of your payment for verification purposes.</p>
        <p className='text-light-dark mb-1'>Verification and Processing:</p>
        <p className='text-light-dark'>After successful verification, we will initiate your refund request. Please allow some time for the refund to be processed.</p>
        <p className='text-light-dark'>Thank you for your understanding and cooperation.</p>
      </div>
      <Footer />
    </>
  )
}
