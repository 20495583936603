import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Footer() {

    const navigate = useNavigate();

    return (
        <div className='d-flex justify-content-center align-items-center my-5 py-5'>
            <div className='text-center'>
                <div>Copyright © 2024 cashwith<span className='text-teal'>chatgpt</span></div>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <p className='m-0 footer-link' onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
                    <span className='mx-2'>|</span>
                    <p className='m-0 footer-link' onClick={() => navigate('/refund-polic')}>Refund Policy</p>
                    <span className='mx-2'>|</span>
                    <p className='m-0 footer-link' onClick={() => navigate('/terms-and-conditions')}>Terms and Conditions</p>
                </div>
            </div>
        </div>
    )
}