import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router-dom';

export default function TermsAndConditions() {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Navbar />
      <div className='container mb-5 pb-5'>
        <h2 className='fw-normal display-5 mb-5'>Terms and Conditions</h2>

        <p className='text-light-dark'><span className='fw-bold me-2'>Effective Date:</span>February 10th, 2024</p>
        <p className='text-light-dark'>Welcome to <span className='text-teal cursor-pointer' onClick={() => navigate('/')}>https://www.cashwithchatgpt.com</span> By using our website and services, you agree to comply with the following terms and conditions.</p>

        <p className='fw-bold mb-1'>1. Account Creation: </p>
        <p className='text-light-dark'>To use our services, you must be at least 18 years old and provide accurate information during the signup process.You are responsible for maintaining the security of your account and must not use our services for any illegal activities.</p>

        <p className='fw-bold mb-1'>2. Modifications to the Service: </p>
        <p className='text-light-dark'>We reserve the right to modify or discontinue our services at any time, with or without notice.While we strive to notify you of any significant changes, your continued use of our services constitutes acceptance of these changes.</p>

        <p className='fw-bold mb-1'>3. Copyright and Content Ownership:</p>
        <p className='text-light-dark'>You retain ownership of the content you upload to our platform.By using our services, you grant us a license to use this content for operational purposes.We do not claim ownership of your content.</p>

        <p className='fw-bold mb-1'>4. User Responsibilities:</p>
        <p className='text-light-dark'>You agree to use our services in compliance with all applicable laws and regulations.You are solely responsible for any content you post and must not violate the intellectual property rights of others.</p>

        <p className='fw-bold mb-1'>5. Limitation of Liability:</p>
        <p className='text-light-dark'>We provide our services "as is" and do not guarantee their accuracy, reliability, or availability.We shall not be liable for any damages arising from the use or inability to use our services.</p>

        <p className='fw-bold mb-1'>6. Termination:</p>
        <p className='text-light-dark'>We reserve the right to terminate your account if you violate these terms and conditions or engage in abusive behavior towards our staff or other users.</p>

        <p className='fw-bold mb-1'>7. Contact Us:</p>
        <p className='text-light-dark'>If you have any questions or concerns about these terms and conditions, please contact our support team through the "contact us" link on our website.</p>

        <p className='text-light-dark'>Thank you for using <span className='text-teal cursor-pointer' onClick={() => navigate('/')}>https://www.cashwithchatgpt.com</span> We hope you enjoy our services.</p>
      </div>
      <Footer />
    </>
  )
}