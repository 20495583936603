import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function ReviewSlider() {

    const reviewData = [
        { id: 1, img: '/images/user-1.jpg', name: 'Sarah M.', review: `"As a freelancer, the ChatGPT Prompt Bundle has been a game-changer for me. It's helped me elevate my copywriting skills, attract more clients, and increase my income. Highly recommended!"` },
        { id: 2, img: '/images/user-2.jpg', name: 'John D.', review: `"Being a busy professional, I needed something to boost my productivity and skill set. This bundle did just that! It's transformed the way I manage projects, communicate with clients, and advance in my career."` },
        { id: 3, img: '/images/user-3.jpg', name: 'Rachel W.', review: `"I've always been interested in investing but felt overwhelmed by the complexity of it all. The ChatGPT Prompt Bundle made it easy for me to understand the basics, analyze market trends, and make informed decisions. Thanks to this bundle, I've seen significant growth in my investment portfolio!"` },
        { id: 4, img: '/images/user-4.jpg', name: 'Michael L.', review: `"As a student studying marketing, the ChatGPT Prompt Bundle has been an invaluable resource. It's given me a competitive edge in my classes, allowed me to impress my professors with insightful projects, and set me up for success in my future career."` },
        { id: 5, img: '/images/user-5.jpg', name: 'Emily S.', review: `"I stumbled upon the ChatGPT Prompt Bundle as a stay-at-home mom looking to make some extra money. Little did I know, it would completely change my life! The prompts helped me start my own successful blog and generate income from home. Thank you!"` },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        variableWidth: true,
        arrows: false,
    };

    const reviewCard = () => {
        return (
            <Slider {...settings}>
                {
                    reviewData?.map((review) => (
                        <div key={review?.id} className='review-card-wrapper p-4 m-4'>
                            <div className='d-flex'>
                                <img src={review?.img} />
                                <div className='ms-3'>
                                    <p className='title fw-bold m-0'>{review?.name}</p>
                                    <p className='m-0 mt-2 review'>{review?.review}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Slider>
        )
    }

    return (
        <div className='my-5 pb-5'>
            {reviewCard()}
        </div>
    )
}
