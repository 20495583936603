import React from 'react'

const HeroSection = () => {

    const openInNewTab = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div className="container my-5 py-5">
            <div className="row align-items-center" style={{ minHeight: "420px" }}>
                <div className="col-md-6">
                    <h2 className='display-5 mb-0 fw-normal'><span className='text-teal'>100,000+</span> Ultimate ChatGPT Prompts Collection, 🚀 Unlock Your <span className='text-teal'>Creativity</span> Across Various Niches!</h2>
                </div>
                <div className="col-md-6 mt-5 mt-md-0">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className='prompt-card py-3 pt-2 h-auto'>
                            <div className='d-flex justify-content-between align-items-center pb-2 px-3 border-bottom'>
                                <p className='title m-0'>Prompt Bundles</p>
                                <div className='teal-capsule text-uppercase p-1 ms-3'>Beginner Friendly</div>
                            </div>
                            <div className='pe-3 pt-3'>
                                <ul>
                                    <li>Social Media Marketers</li>
                                    <li>Email Marketing Professionals</li>
                                    <li>Copywriters</li>
                                    <li>Freelancers</li>
                                    <li>Fitness Professionals</li>
                                    <li>Chef/Cooking Professionals</li>
                                    <li>Personal Development Coach</li>
                                    <li>Baby Care</li>
                                    <li>Teachers</li>
                                    <li>Project Managers</li>
                                    <li>Investors (Crypto/Stocks)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center mt-5 py-5'>
                <p className='display-5 mt-2 fw-normal lh-base mt-2'>Actual Cost – Rs. 1999 | Special Discount - Get <span className='text-danger'> 90%</span> OFF</p>
                <p className='display-6 mt-2 fw-light lh-base mt-2'>Limited Time Offer </p>
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <button class="button-teal" role="button" onClick={() => openInNewTab('https://cosmofeed.com/vp/65fa816b51b7df001291280b')}><span class="text text-dark">Buy Now For 199/-</span></button>
                </div>
            </div>
        </div>
    )
}

export default HeroSection