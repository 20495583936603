import React from 'react';
import Navbar from '../Components/Navbar';
import HeroSection from '../Components/HeroSection';
import About from '../Components/About';
import Services from '../Components/Services';
import ReviewSlider from '../Components/ReviewSlider';
import EducationProgramm from '../Components/EducationProgramm';
import Footer from '../Components/Footer';

const Home = () => {
    return (
        <>
            <Navbar />
            <HeroSection />
            <About />
            <Services />
            <ReviewSlider />
            <EducationProgramm />
            <Footer/>
        </>
    )
}

export default Home