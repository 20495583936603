import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy() {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Navbar />
      <div className='container mb-5 pb-5'>
        <h2 className='fw-normal display-5 mb-5'>Privacy Policy</h2>

        <p className='text-light-dark'><span className='fw-bold me-2'>Last updated:</span>July 23, 2023 </p>
        <p className='text-light-dark'>Welcome to <span className='text-teal cursor-pointer' onClick={() => navigate('/')}>https://www.cashwithchatgpt.com</span> This Privacy Policy explains how we collect, use, and disclose your information when you use our service, and it outlines your privacy rights and legal protections.</p>
        <p className='fw-bold'>Interpretation and Definitions:</p>
        <p className='fw-bold mb-1'>Interpretation:</p>
        <p className='text-light-dark'>Words with initial capitals have specific meanings defined in the following sections. These definitions apply regardless of whether the terms are singular or plural.</p>
        <p className='fw-bold mb-1'>Definitions:</p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Account:</span>A unique account created for you to access our service. </p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Affiliate:</span>An entity that controls, is controlled by, or is under common control with us.</p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Company:</span>Also referred to as "we," "us," or "our" in this document, it pertains to<span className='text-teal cursor-pointer' onClick={() => navigate('/')}> https://www.cashwithchatgpt.com.</span></p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Cookies:</span>Small files placed on your device by a website, storing details of your browsing history.</p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Country:</span>Refers to California, United States.</p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Device:</span>Any device capable of accessing our service, such as a computer, cellphone, or tablet.</p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Personal Data:</span>Information relating to an identified or identifiable individual.</p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Service:</span>Our website, <span className='text-teal cursor-pointer' onClick={() => navigate('/')}> https://www.cashwithchatgpt.com.</span></p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Service Provider:</span>A third-party entity employed by us to assist in service provision.</p>
        <p className='text-light-dark'><span className='fw-bold me-2'>Usage Data:</span>Data collected automatically during your use of our service. </p>

        <p className='fw-bold'>Collecting and Using Your Personal Data</p>
        <p className='fw-bold'>Types of Data Collected:</p>
        <p className='fw-bold me-2'>Personal Data:</p>
        <p className='fw-bold me-2 mb-1'>When you use our service, we may ask you to provide certain personally identifiable information, such as:</p>
        <p className='text-light-dark mb-1'>Email address</p>
        <p className='text-light-dark'>First and last name</p>

        <p className='fw-bold me-2 mb-1'>Usage Data: </p>
        <p className='text-light-dark'>We automatically collect usage data, including your device's IP address, browser type, pages visited, and time spent on those pages.</p>

        <p className='fw-bold me-2 mb-1'>Tracking Technologies and Cookies:</p>
        <p className='fw-bold me-2 mb-1'>We use cookies and similar technologies to track activity on our service and store certain information. These may include:</p>
        <p className='text-light-dark mb-1'>Necessary cookies for basic service functionality. </p>
        <p className='text-light-dark mb-1'>Cookies for accepting our cookies policy.</p>
        <p className='text-light-dark'>Functionality cookies for personalizing your experience.</p>

        <p className='fw-bold mb-1'>Use of Your Personal Data: </p>
        <p className='fw-bold mb-1'>We may use your personal data for various purposes, including:</p>
        <p className='text-light-dark mb-1'>Providing and maintaining our service.</p>
        <p className='text-light-dark mb-1'>Managing your account. </p>
        <p className='text-light-dark mb-1'>Contacting you for updates or informative communications.</p>
        <p className='text-light-dark mb-1'>Providing news, special offers, and general information.</p>
        <p className='text-light-dark'>Analyzing usage trends and improving our service.</p>

        <p className='fw-bold mb-1'>Sharing of Your Personal Data: </p>
        <p className='text-light-dark'>We may share your personal data in certain situations, including with service providers, affiliates, business partners, and other users, with your consent.</p>

        <p className='fw-bold mb-1'>Retention and Transfer of Your Personal Data:</p>
        <p className='text-light-dark'>We retain your personal data only as long as necessary and take steps to ensure its security during transfer.</p>

        <p className='fw-bold mb-1'>Deletion of Your Personal Data:</p>
        <p className='text-light-dark'>You have the right to request deletion of your personal data, which we will accommodate where legally permissible.</p>

        <p className='fw-bold mb-1'>Disclosure of Your Personal Data:</p>
        <p className='text-light-dark'>We may disclose your personal data in certain circumstances, including business transactions and legal requirements.</p>

        <p className='fw-bold mb-1'>Security of Your Personal Data: </p>
        <p className='text-light-dark'>While we strive to protect your personal data, no method of transmission over the internet is 100% secure.</p>

        <p className='fw-bold mb-1'>Children's Privacy:</p>
        <p className='text-light-dark'>Our service is not directed at individuals under the age of 13, and we do not knowingly collect personal data from them.</p>

        <p className='fw-bold mb-1'>Links to Other Websites:</p>
        <p className='text-light-dark'>Our service may contain links to third-party websites, which are subject to their own privacy policies.</p>

        <p className='fw-bold mb-1'>Changes to this Privacy Policy:</p>
        <p className='text-light-dark'>We may update this privacy policy, and we will notify you of any changes via email or a notice on our service.</p>

        <p className='fw-bold mb-1'>Contact Us:</p>
        <p className='text-light-dark'>If you have any questions about this Privacy Policy, you can contact us at
          <span>
            <a className='mail-link' href="mailto:cashwithchatgpt@gmail.com" target='_blank'>
              <span className='text-teal'> cashwithchatgpt@gmail.com.</span>
            </a>
          </span>
        </p>
      </div>
      <Footer/>
    </>
  )
}