import React from 'react'

const About = () => {
    return (
        <div className="container mt-5 pt-5">
            <h2 className='fw-normal display-5 text-center'>Why Choose Our <span className='text-teal'>Bundle?</span></h2>
            <div className='choose-bundle-wrapper mt-5 mx-auto'>
                <div className='d-flex justify-content-start'>
                    <img src='/images/check.svg' height={20} alt='tick-icon' />
                    <p className='ms-3 mb-0 fs-4 lh-1'>Unparalleled Variety: From business to parenting, find prompts for every niche, ensuring you're never short of ideas.</p>
                </div>
                <div className='d-flex justify-content-start mt-3'>
                    <img src='/images/check.svg' height={20} alt='tick-icon' />
                    <p className='ms-3 mb-0 fs-4 lh-1'>Time-Saving: Skip the brainstorming process and dive straight into creating top-notch content.</p>
                </div>
                <div className='d-flex justify-content-start mt-3'>
                    <img src='/images/check.svg' height={20} alt='tick-icon' />
                    <p className='ms-3 mb-0 fs-4 lh-1'>Versatility: Ideal for bloggers, marketers, educators, and anyone seeking creative inspiration</p>
                </div>
                <div className='d-flex justify-content-start mt-3'>
                    <img src='/images/check.svg' height={20} alt='tick-icon' />
                    <p className='ms-3 mb-0 fs-4 lh-1'>Instant Download</p>
                </div>
                <div className='d-flex justify-content-start mt-3'>
                    <img src='/images/check.svg' height={20} alt='tick-icon' />
                    <p className='ms-3 mb-0 fs-4 lh-1'>Life Time Access</p>
                </div>
                <div className='d-flex justify-content-start mt-3'>
                    <img src='/images/check.svg' height={20} alt='tick-icon' />
                    <p className='ms-3 mb-0 fs-4 lh-1'>Risk-Free Purchase</p>
                </div>
            </div>
        </div>
    )
}

export default About