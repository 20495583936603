import React from 'react'

export default function EducationProgramm() {

    const Data = [
        { title: '🏋️‍♂️ Fitness', prompts: '16,000', description: 'Crush your fitness goals with expert prompts tailored to enhance your workouts, optimize nutrition, and cultivate a healthier lifestyle.' },
        { title: '🚀 Personal Development', prompts: '14,400', description: 'Transform into the best version of yourself with prompts designed to boost productivity, foster mindfulness, and unleash your inner potential.' },
        { title: '👶 Baby Care', prompts: '18,300', description: 'Navigate parenthood like a pro with prompts covering everything from newborn essentials to nurturing healthy development.' },
        { title: '✉️ Email Marketing', prompts: '20,000', description: 'Elevate your marketing game with strategic prompts to craft compelling emails, build customer relationships, and drive conversions.' },
        { title: '🍳 Cooking', prompts: '7000', description: `Master the kitchen with prompts for delicious recipes and essential cooking techniques. From beginner basics to gourmet delights, this bundle has your culinary journey covered. Don't miss out – it's the spice your prompt collection needs!` },
        { title: '💰 Crypto & Stocks', prompts: '24,500', description: 'Stay ahead in the volatile world of cryptocurrencies and stocks with prompts to analyze trends, make informed decisions, and capitalize on opportunities.' },
        { title: '📊 Project Management', prompts: '4100', description: 'Master the art of project management with prompts to streamline workflows, boost team collaboration, and deliver results with precision.' },
        { title: '📱 Social Media Marketing', prompts: '17,500', description: 'Dominate social media platforms with prompts to create compelling content, engage your audience, and drive brand growth.' },
        { title: '✍️ Copywriting', prompts: '23,000', description: 'Craft captivating copy that converts with prompts to hone your writing skills, master persuasive language, and create impactful marketing messages.' },
    ];

    const openInNewTab = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div className='container my-5 education-programs'>
            <div className='py-5 text-center'>
                <h2 className='fw-normal display-5 text-teal'>What's Inside</h2>
                <p className='description mx-auto mt-3'>Welcome to your <span className='text-teal'> one-stop solution</span> for mastering diverse industries and skyrocketing your success! Our <span className='text-teal'>ChatGPT Prompt Bundle</span> is meticulously crafted to empower you across a spectrum of essential domains, ensuring you stay ahead of the curve in today's dynamic market. Here's what awaits you:</p>
            </div>

            <div className='row m-0 p-0'>
                {
                    Data?.map((item, index) => (
                        <div className='col-lg-4 col-md-6 px-3' key={index}>
                            <div className='p-5 program-card text-center my-3'>
                                <p className='digits m-0 text-teal fw-normal'>{item?.prompts}+</p>
                                <p className='text-white fs-5'>{item?.title} Prompts</p>
                                <p className='text-grey'>{item?.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='d-flex align-items-center justify-content-center my-5'>
                <button class="button-teal" role="button" onClick={() => openInNewTab('https://cosmofeed.com/vp/65fa816b51b7df001291280b')}><span class="text text-dark">Buy Now For 199/-</span></button>
            </div>
        </div>
    )
}
